import { buildHeaders } from 'application/services/buildHeaders';
import { ApiError, ApiRedirect } from 'helpers/error';
import axios from 'axios';
import { CONTENT_API_URL, DOMAIN_PORT, DOMAIN_PROTOCOL } from './constants';

export interface RequestInterface {
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	url?: string;
	query?: string;
	method: 'GET' | 'POST';
	language?: string;
	locale?: string;
	accessToken?: string;
	data?: any;
}


export const getCMSContent = <TResponse = any>({
	host,
	preview,
	previewData,
	method,
	query,
	url,
	language,
	locale,
	accessToken,
}: RequestInterface): Promise<TResponse> => {
	if (!CONTENT_API_URL) {
		throw new ApiError(
			'CONTENT_API_URL:NOTDEFINED',
			500,
			'Content api url is not defined',
		);
	}

	if (url === 'umbraco') {
		throw new ApiError('PageNotFound', 404, 'Page not found');
	}

	if (host === 'localhost' && process.env.FALLBACK_HOSTNAME) {
		host = process.env.FALLBACK_HOSTNAME;
	}

	const domain = `${DOMAIN_PROTOCOL ? `${DOMAIN_PROTOCOL}://` : ''}${host}${DOMAIN_PORT ? `:${DOMAIN_PORT}` : ''
		}/`;

	const headers = buildHeaders({
		domain,
		preview,
		previewData,
		locale,
		accessToken,
	});

	return axios({
		url: `${CONTENT_API_URL}${url ? url : ''}${preview ? '?preview=1' : ''
			}`,
		method: method,
		maxRedirects: 0,
		headers,
		data: {
			query,
		},
	})
		.then((res) => {
			if (method === 'POST') {
				const { data, errors } = res.data;

				if (errors) {
					throw new Error(errors[0].message);
				}

				return data;
			}

			if (method === 'GET') {
				const { data } = res;

				return data;
			}
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;

			if (statusCode === 404) {
				throw new ApiError('PageNotFound', 404, 'Page not found');
			}

			if (statusCode === 401 || statusCode === 403) {
				// "401 Unauthorized" really means "unauthenticated"
				// "403 Forbidden" really means "unauthorized"
				return error?.response?.data?.data;
			}

			if (
				statusCode === 301 ||
				statusCode === 302 ||
				statusCode === 307 ||
				statusCode === 308
			) {
				throw new ApiRedirect(statusCode, error.response.data.url);
			}

			if (statusCode === 500) {
				throw new ApiError('InternalServerError', 500, message);
			}

			throw new ApiError('Unknown error', statusCode, message);
		});
};
