import { getCMSContent } from 'application/adapters/cms/contentAdapter';

export interface GetCultureInterface {
	host: string | string[];
	locale: string;
}

export const getCulture = async ({
	host,
	locale,
}: GetCultureInterface): Promise<Umbraco.Cultures> => {
	const query = `{
		page {
			culture
		}
	}`;

	const response = await getCMSContent({
		host,
		query,
		method: 'POST',
		locale: locale,
	});

	return response?.page?.culture;
};
