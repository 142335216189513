import { getCulture } from 'application/repositories/cultureRepository';
import { getCMSContent } from 'application/adapters/cms/contentAdapter';
import axios from 'axios';
import { ApiError } from 'helpers/error';

export interface PageContentResponse {
	root: {
		properties: Umbraco.SiteSetting;
		header: Umbraco.HomePage[];
	};
	page: Umbraco.HomePage;
	footer: { pageFooter: Umbraco.FooterCard[] };
	contactLink: { contactLink: Umbraco.Link };
	some: { socialLinks: Umbraco.SocialLink[] };
	metaData?: Umbraco.MetaData;
	dictionary: Umbraco.DictionaryItem[];
	accessToken: any;
	restrictedPage?: boolean;
	contactFormData: Umbraco.ModalHelpForm;
	signupFormData: Umbraco.ModalSignupForm;
	businessPartners: Models.BusinessPartner[];
	order: Models.Order;
	culture: Umbraco.Cultures;
	host: string;
	languageSelector: Umbraco.LanguageSelector;
	locations: Umbraco.Locations;
}
export interface GetPageContentProps {
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	url: string;
	language?: string;
	locale?: string;
	accessToken?: string;
}

export const getPageContent = async ({
	url,
	host,
	language,
	locale,
	preview,
	previewData,
	accessToken,
}: GetPageContentProps): Promise<PageContentResponse> => {
	const culture = await getCulture({ host, locale });
	const query = `{
		root: page(path: "/")
		{
			properties(fields: ["siteSettings"])
			header: children {
				name
				url
				id
				properties(fields: ["pageSettings", "title"])
			}
		}
		page: page {
			id
			name
			documentType
			url
			properties
			createDate
			updateDate
			template
			availableUrls {
				culture
				text
				isUrl
			}
			# culture
		}
		footer: field(path:"/${url}", field: "pageFooter")
		some: field(path:"/${url}", field: "socialLinks")
		contactLink: field(path:"/", field: "contactLink")
		dictionary: dictionary {
			key
			values(cultures: ["${culture}"]) {
				culture
				value
			}
		}
	}`;

	return getCMSContent({
		host,
		query,
		method: 'POST',
		preview,
		previewData,
		url,
		language,
		locale,
		accessToken,
	});
};

export const get404Content = async (): Promise<any> => {
	try {
		const res = await axios('/api/get404content');
		return res.data;
	} catch (err) {
		const errorCode = err?.response?.status ?? 500;
		const errorMessage = err?.message ?? 'Unknown error';
		throw new ApiError(
			'[genericRequest]',
			errorCode,
			`Request to '/404 threw an error: ${errorMessage}`,
		);
	}
};
