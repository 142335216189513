import { Main } from 'ui/components/4-habitats/Main';
import { Header } from 'ui/components/4-habitats/Header';
// import { Footer } from 'ui/components/4-habitats/Footer';
// import { FooterCard } from 'ui/components/2-molecules/Card/FooterCard';
import { HeroCard } from 'ui/components/2-molecules/Card/HeroCard';
import { useAuthenticationContext } from 'application/contexts/AuthenticationContext';
import { signIn, signOut } from 'next-auth/react';
import axios from 'axios';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { useContext } from 'react';

export interface ErrorPageProps {
	errorCode: 404 | 500;
	message: string;
	logoClicked: any;
	href: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
	errorCode,
	message,
	logoClicked,
	href,
}) => {
	const authentication = useAuthenticationContext();
	const dictionary = useContext(DictionaryContext);
	async function handleSignOut() {
		await axios
			.get(
				`https://${process.env.NEXT_PUBLIC_AUTHENTICATION_DOMAIN}/Account/LogoutHeadless`,
			)
			.catch((error) => {
				// Log but otherwise ignore error. A failed logout in the backend is not critical.
				console.error(error);
			});
		signOut();
	}

	//TODO: Maybe figure out how to get basketUrl here?
	const handleGoToCheckout = null;

	const avatarDictionary = {
		actionLogin: dictionary.getValue('Action.Login', null, 'Login'),
	};

	return (
		<>
			<Header
				logoClicked={logoClicked}
				href={href}
				onSignIn={() => {
					signIn('authServer');
				}}
				onSignOut={handleSignOut}
				profile={authentication?.user}
				handleGoToCheckout={handleGoToCheckout}
				avatarDictionary={avatarDictionary}
			/>
			<Main>
				<HeroCard heading={errorCode.toString()} kicker={message} />
			</Main>
		</>
	);
};
